.my-memberships-gallery {
	background-color: #f3f3f3;
	width: 100vw;
	height: auto;
	position: relative;
	left: calc(-50vw + 50%);
	margin-top: 4vh;
}
.gallery-trans {
	width: 100vw;
	height: auto;
	position: relative;
	left: calc(-50vw + 50%);
}

.list-group-item {
	background-color: inherit;
}

.cards__wrapper {
	position: relative;
	margin: 50px 0 45px;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.cards__items {
	margin-bottom: 24px;
	padding-inline-start: 0;
}
