.gallery {
	background-color: #f3f3f3;
	width: 100vw;
	height: auto;
	position: relative;
	left: calc(-50vw + 50%);
	margin-top: 4vh;
}
.gallery-trans {
	width: 100vw;
	height: auto;
	position: relative;
	left: calc(-50vw + 50%);
}

.list-group-item {
	background-color: inherit;
}

.option_cards__wrapper {
	position: relative;
	margin: 50px 0 45px;
}

.option_cards__items {
	margin-bottom: 24px;
	padding-inline-start: 0;
}

.option_cards__item {
	display: flex;
	flex: 1;
}

.option_cards__item__link {
	display: flex;
	flex-flow: column;
	width: 100%;
	box-shadow: 0 6px 20px rgba(56, 125, 255, 0.017);
	-webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
	filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
	overflow: hidden;
	text-decoration: none;
	background-color: #fff;
}

.option_cards__item__pic-wrap {
	position: relative;
	width: 100%;
	height: 200px;
	overflow: hidden;
}

.option_cards__item__pic-wrap-no-dc {
	position: relative;
	width: 100%;
	padding-top: 67%;
	overflow: hidden;
}

.option_cards__item__pic-wrap-no-box {
	position: relative;
	width: 100%;
	height: 200px;
	overflow: hidden;
}

.fade-img {
	animation-name: fade-img;
	animation-duration: 2s;
}

.option_cards__item__pic-wrap::after {
	visibility: var(--data-category-vis);
	content: attr(data-category);
	position: absolute;
	bottom: 0;
	margin-left: 10px;
	padding: 6px 8px;
	max-width: calc((100%) - 60px);
	font-size: 12px;
	font-weight: 700;
	color: #fff;
	background-color: #000000;
	box-sizing: border-box;
}

.option_cards__item-short {
	max-height: 200px;
}

.option_cards__item__img {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: block;
	width: 100%;
	max-width: 100%;
	height: 100%;
	max-height: 100%;
	object-fit: cover;
	transition: all 0.2s linear;
}
.option_cards__item__img {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: block;
	width: 100%;
	max-width: 100%;
	height: 100%;
	max-height: 100%;
	object-fit: cover;
	transition: all 0.2s linear;
}

.option_cards__item__img:hover {
	transform: scale(1.1);
}

.option_cards__item__info {
	padding: 20px 30px 30px;
}

.option_cards__item__title {
	color: #252e48;
	font-size: 18px;
	line-height: 24px;
}

@media only screen and (min-width: 1200px) {
	.content__blog__container {
		width: 84%;
	}
}

@media only screen and (min-width: 1024px) {
	.option_cards__items {
		display: flex;
	}
}

@media only screen and (max-width: 1024px) {
	.option_cards__item {
		margin-bottom: 2rem;
	}
}
