.gallery {
	background-color: #f3f3f3;
	width: 100vw;
	height: auto;
	position: relative;
	left: calc(-50vw + 50%);
	margin-top: 4vh;
}
.gallery-trans {
	width: 100vw;
	height: auto;
	position: relative;
	left: calc(-50vw + 50%);
}

.list-group-item {
	background-color: inherit;
}

.cards__wrapper {
	position: relative;
	margin: 50px 0 45px;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.cards__items {
	margin-bottom: 24px;
	padding-inline-start: 0;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.cards__item {
	display: flex;
	flex: 1;
	margin: 1rem 1rem;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.cards__item__link {
	display: flex;
	flex-flow: column;
	width: 100%;
	box-shadow: 0 6px 20px rgba(56, 125, 255, 0.017);
	-webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
	filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
	overflow: hidden;
	text-decoration: none;
	background-color: #fff;
}

.cards__item__pic-wrap {
	position: relative;
	width: 100%;
	padding-top: 67%;
	overflow: hidden;
}

.fade-img {
	animation-name: fade-img;
	animation-duration: 2s;
}

/* .cards__item__pic-wrap::after {
	visibility: attr(data-category);
	content: attr(data-category);
	position: absolute;
	bottom: 0;
	margin-left: 10px;
	padding: 6px 8px;
	max-width: calc((100%) - 60px);
	font-size: 12px;
	font-weight: 700;
	color: #fff;
	background-color: #000000;
	box-sizing: border-box;
} */

.cards__item__img {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: block;
	width: 100%;
	max-width: 100%;
	height: 100%;
	max-height: 100%;
	object-fit: cover;
	transition: all 0.2s linear;
}

/* .cards__item__img:hover {
	transform: scale(1.1);
} */

.cards__item-short {
	max-height: 200px;
}

.cards__item__info {
	width: 100%;
	text-align: left;
	padding: 30px 20px;
	word-wrap: break-word;
	overflow-wrap: break-word;
	white-space: normal;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.cards__item__title {
	color: #252e48;
	font-size: 18px;
	line-height: 24px;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

video {
	object-fit: cover;
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: -1;
}

.hero-container {
	/* background: url('/images/main.JPG') center center/cover no-repeat; */
	height: 40vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
	object-fit: contain;
}

@media only screen and (min-width: 1200px) {
	.content__blog__container {
		width: 84%;
	}
}

@media only screen and (min-width: 1024px) {
	.cards__items {
		display: flex;
	}
}

@media only screen and (max-width: 1024px) {
	.cards__item {
		margin-bottom: 2rem;
	}
}
