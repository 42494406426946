.date-card {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-family: 'Arial', sans-serif;
	border: 1px solid #ddd;
	padding: 6px;
	width: 60px;
	text-align: center;
	border-radius: 0px;
	background-color: #f8f8f8;
}

.date-card__month {
	font-size: 14px;
	font-weight: bold;
}

.date-card__day {
	font-size: 20px;
	color: #5a5a5a; /* Dark gray */
	font-weight: bold;
}

.date-card__year {
	font-size: 12px;
	color: #5a5a5a; /* Dark gray */
}
