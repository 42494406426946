/* Container for the gallery with horizontal scrolling */
.gallery-container {
	padding-bottom: 1rem;
}

.cards__wrapper {
	overflow-x: auto; /* Enables horizontal scrolling */
	white-space: nowrap; /* Prevents items from wrapping */
	margin: 50px 0 0;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

/* Wrapper for the items, with reduced spacing between them */
.cards__items {
	display: flex;
	gap: 0px; /* Reduced space between items */
	padding: 0;
	list-style: none;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

/* Each item in the gallery */
.cards__item {
	width: 100%; /* Make the card fill the full width of the column */
	flex: 1 0 auto; /* Allow flexibility */
	box-shadow: 0px 0px 15px #f5f5f5;
	overflow: hidden; /* Prevents inner content from overflowing */
	min-width: 225px;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

/* Link structure inside each item */
.cards__item__link {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-decoration: none;
	background-color: #fff;
	padding: 0; /* No extra padding around image and button */
	overflow: hidden;
}

/* Image container to maintain aspect ratio */
.cards__item__pic-wrap-no-box {
	position: relative;
	width: 100%;
	padding-top: 67%; /* Aspect ratio for the image container */
	overflow: hidden;
	margin: 0; /* Remove any default margin */
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.cards__item__pic-wrap-no-dc {
	position: relative;
	width: 100%;
	padding-top: 67%;
	overflow: hidden;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

/* Image inside the container */
.cards__item__img {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: transform 0.2s;
}

/* Zoom effect on image hover */
.cards__item__img:hover {
	transform: scale(1.1);
}

/* Title text styling with wrapping enabled */
.cards__item__title {
	font-size: 16px;
	color: #252e48;
	margin-top: 0px;
	margin-right: 0px;
	margin-left: 0px;
	text-align: left;
	word-wrap: break-word; /* Enable text wrapping if needed */
	overflow-wrap: break-word;
	white-space: normal; /* Allows text to wrap to the next line */
}

.cards__item__text {
	text-align: left;
	word-wrap: break-word; /* Enable text wrapping if needed */
	overflow-wrap: break-word;
	white-space: normal; /* Allows text to wrap to the next line */
}

/* Button styling */
.cards__item .btn-block {
	width: 100%; /* Full width for the button */
	text-align: center;
	background-color: #000; /* Change color as needed */
	color: #fff;
	border: none;
	margin-top: 0.25rem; /* Reduced margin for tighter layout */
	font-size: 14px;
}
