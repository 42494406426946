main {
	min-height: 80vh;
}

.h3 {
	padding: 1rem 0;
}

.rating span {
	margin: 0.1rem;
}

h1 {
	font-size: 1.8rem;
	padding: 0.1rem;
}

h2 {
	font-size: 1.4rem;
	padding: 0.5rem 0;
}
.nav-item {
	cursor: pointer;
}

.navbar-custom .navbar-nav .nav-link {
	color: black !important;
	margin-right: 2rem !important;
	padding: 0 !important;
}

.navbar-custom .navbar-brand {
	color: black !important;
	margin-right: 2rem !important;
	padding: 0.3rem, 0 !important;
}

/* *:not(html) {
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
} */
